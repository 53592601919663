<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getHistoryTheme } from "@/assets/style/theme/theme"; // 引入切换主题方法
import { mapState } from "vuex";
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    getHistoryTheme();

  },
  computed: {
    ...mapState({
      EditFormCon: (state) => state.common.EditFormCon,
      MenusArr: state => state.menus.MenusArr
    }),
  },
  watch: {
    MenusArr: {
      handler(v) {
        if (!v || v.length === 0) return;

        v.forEach((item, index) => {
          if (item.path === this.$route.path) {
            this.$store.commit("SET_MENUS_INDEX", index)
          }
        });
      },
      immediate: true
    }
  }
};
</script>

<style lang="less">
.messageIndex200 {
  z-index: 300000 !important;
}

.myMessageClass {
  z-index: 9000 !important;
}

.te_boxaa {
  width: 100%;

  .topBox {
    padding: 10px 0;
    border-bottom: 1px solid #000;
  }

  .content {
    padding: 10px 0;

    .top {
      padding: 5px 0;
      display: flex;

      .a {
        display: block;
        width: 88px;
      }

      .t {
        flex: 1;
      }
    }

    .name {
      display: flex;

      .name_a {
        display: block;
        width: 88px;
      }

      .t {
        flex: 1;
      }
    }
  }
}
</style>
